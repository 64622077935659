// colors from dark to light

// Browns
export const brown1 = '#5E4D42'
export const brown2 = '#BFB8B4'
export const brown3 = '#ECEAE8'
export const brown4 = '#F7F6F6'
export const brown5 = '#9e948e'

// Blues
export const blue1 = '#001A45'
export const blue2 = '#88ABB9'
export const blue3 = '#C4D5DC'
export const blue4 = '#CFDDE3'

// Greys
export const coolGrey = '#56565C'
export const grey1 = '#4D4D4D'
export const grey2 = '#999999'
export const grey3 = '#CCCCCC'


export const black = '#000'
export const white = '#fff'