import { css } from 'styled-components'
import { brown2, brown1, brown5 } from './colors'
import { media } from './utils'

export const heading1 = css`
    font-family: Chronicle Display;
    font-weight: 100;
    font-size: 72px;
    line-height: 1.389;

    ${media.tabletLandscape`
        font-size: 48px;
        line-height: 1.19;
    `}
`
export const heading2 = css`
    font-family: Chronicle Display;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.167;

    ${media.tabletLandscape`
        font-size: 20px;
        line-height: 1.222;
    `}
`
export const heading3 = css`
    font-family: Basis Grotesque;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.25;

    ${media.tabletLandscape`
        font-size: 14px;
        line-height: 1.429;
    `}
`
export const subheading1 = css`
    font-family: Basis Grotesque;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
`
export const subheading2 = css`
    font-family: Basis Grotesque;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.67;
`
export const body = css`
    font-family: Basis Grotesque;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.25;

    ${media.tabletLandscape`
        font-size: 16px;
        line-height: 1.375;
    `}
`
export const navigation = css`
    font-family: Basis Grotesque;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
`
export const links = css`
    display: inline-block;
    padding-bottom: 3px;
    border-bottom: 1px solid ${brown5};
    font-family: Basis Grotesque;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    color: ${brown5};

    ${media.tabletLandscape`
        font-size: 12px;
    `}
`
export const caption = css`
    font-family: Basis Grotesque;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
`
export const numbers = css`
    font-family: Basis Grotesque;
    font-weight: 300;
    font-size: 160px;
    line-height: 1;

    ${media.tabletLandscape`
        font-size: 100px;
    `}
`
