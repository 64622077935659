import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { grey, brown1, blue2 } from './colors';
import { subheading2 } from './type';

const maxWidth = 1440;
export const headerHeight = 65;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: ${brown1};
        font-family: 'Basis Grotesque';
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    /* a, p, div {
        color: black;
    } */

    p {
        margin-top: 0;
        margin-bottom: 0.85em;
    }
    
    ul, ol {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 2em;
        padding-left: 38px;
        counter-reset: custom-counter;

        li {
            counter-increment: custom-counter;
            margin-top: 1em;

            &::before {
                content: counter(custom-counter);
                position: absolute;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
                margin-top: 2px;
                background: white;
                border-radius: 50%;
                ${subheading2};
                line-height: 28px;
                color: ${blue2};

                ${media.tabletLandscape`
                    margin-top: -4px;
                `}

                ${media.phone`
                    margin-top: -2px;
                `}
            }
        }
    }

    b, strong {
        font-weight: 400;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    /* Parallax */

    .parallax-inner {
        will-change: transform;
    }


`;

export const container = css`
	width: 100%;
    max-width: ${maxWidth}px;
    margin-left: auto;
    margin-right: auto;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;

    ${media.tabletLandscape`
        padding-left: 50px; 
        padding-right: 50px;
    `}

    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
    `}

    ${media.phone`
        padding-left: 30px; 
        padding-right: 30px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const filterItem = css`
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;
    ${hoverState}

    ${props => {
        if (props.active) return css`
            font-weight: 600;
        `
    }}
`

// Parallax 

export const parallaxProps = {
    y: [-30, 0]
}