import React from 'react'
import { Provider } from 'react-redux'
import store from './src/store/configureStore'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';

export default ({ element, props  }) => {
    return (
        <Provider 
            store={store}
        >   
            <GlobalStyles/>
            {element}
        </Provider>
    )
}