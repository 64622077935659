import { css } from 'styled-components';
import { useMedia } from 'react-use';

export const breakpoints = {
    desktopLarge: 1920,
    desktop: 1600,
    desktopSmall: 1440,
    tabletLandscape: 1175,
    tablet: 768,
    phone: 640,
}

const setMedia = size => (...args) => css`
    @media (max-width: ${size}px) {
        ${css(...args)}
    }
`

export const isClient = () => {
	if (typeof window !== 'undefined') {
		return true
	}

	return false;
}

const getMedia = (breakpoint) => () => {
    if (typeof navigator !== "undefined" && window && window.innerWidth <= breakpoints[breakpoint]) {
        return true
    }

    return false;
}

export const media = {
    desktopLarge: setMedia(breakpoints.desktopLarge),
    desktop: setMedia(breakpoints.desktop),
    desktopSmall: setMedia(breakpoints.desktopSmall),
    tabletLandscape: setMedia(breakpoints.tabletLandscape),
    tablet: setMedia(breakpoints.tablet),
    phone: setMedia(breakpoints.phone)
};

export const useBreakpoint = (breakpoint) => {
    return useMedia(`(max-width: ${breakpoints[breakpoint]}px)`)
}
